<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/store/store' }">门店列表</el-breadcrumb-item>
                <el-breadcrumb-item>门店详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div style="height: 20px;"></div>
        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>门店详情</h3>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <el-row>
                <el-col :span="16">
                    <el-form v-loading="loading" size="medium" :label-width="this.env.label_width">

                        <el-form-item label="所属企业">
                            <span class="form-span-text">{{ info.company_name }}</span>
                        </el-form-item>
                        <el-form-item label="门店名称">
                            <el-tag size="mini" v-if="info.oss_arrangement === 1">平台可排课</el-tag>&nbsp;&nbsp;
                            <span class="form-span-text">{{ info.store_name }}</span>

                        </el-form-item>
                        <el-form-item label="门店状态">
                            <el-tag size="mini" v-if="info.examine_status === 0">审核中</el-tag>
                            <el-tag size="mini" v-if="info.examine_status === 1" type="success">审核通过</el-tag>
                            <el-tag size="mini" v-if="info.examine_status === 2" type="danger">审核失败</el-tag>
                            <el-tag size="mini" v-if="info.examine_status === 3" type="danger">违规下架</el-tag>
                        </el-form-item>
                        <el-form-item label="上架状态">
                            <el-tag size="mini" v-if="info.publish_state === 0" type="warning">下架</el-tag>
                            <el-tag size="mini" v-if="info.publish_state === 1" type="success">上架</el-tag>
                        </el-form-item>
                        <el-form-item label="是否使用优惠券">
                            <el-tag size="mini" v-if="info.use_coupon === 1" type="success">使用优惠券</el-tag>
                            <el-tag size="mini" v-if="info.use_coupon === 0" type="warning">不使用优惠券</el-tag>
                        </el-form-item>
                        <el-form-item label="运动品类">
                            <div class="form-tag_colour-div">
                                <el-tag :key="item.index" v-for="item in info.motion_category_two"
                                    class="form-tag_colour-tag" effect="dark">
                                    {{ item.name }}
                                </el-tag>
                            </div>
                        </el-form-item>
                        <el-form-item label="门店视频">
                            <Qnupload v-model="info.store_video" type="video" :readonly='true' />
                        </el-form-item>
                        <el-form-item label="门店照片">
                            <Qnupload v-model="info.store_photo" :sum="5" :readonly='true' />
                        </el-form-item>
                        <el-form-item label="负责人">
                            <span class="form-span-text">{{ info.responsible }}</span>
                        </el-form-item>
                        <el-form-item label="门店电话">
                            <span class="form-span-text">{{ info.store_phone }}</span>
                        </el-form-item>
                        <el-form-item label="门店微信">
                            <Qnupload v-model="info.store_wx" :sum="1" :readonly='true' />
                        </el-form-item>
                        <el-form-item label="地址">
                            <span class="form-span-text">{{ info.province }}{{ info.city }}{{ info.county }}{{
                                info.address
                            }}</span>
                        </el-form-item>
                        <el-form-item label="地图">
                            <Amap ref="amap_info" idindex="info" style="height: 600px;" />
                            <span class="form-span-text" v-if="!this.Tool.is_empty(info.longitude)">经纬度:&nbsp;&nbsp;
                                <span>{{ info.longitude }}</span>&nbsp;,&nbsp;<span>{{ info.latitude }}</span>
                            </span>
                        </el-form-item>
                        <el-form-item label="交通信息">
                            <span class="form-span-text">{{ info.trafficInfo }}</span>
                        </el-form-item>
                        <el-form-item label="门店简介">
                            <el-card class="box-card">
                                <!--<div class="editor-content-see" v-html="info.introduce"></div>-->
                                <span class="form-span-text">{{ info.introduce }}</span>
                            </el-card>
                        </el-form-item>
                        <el-form-item label="注意事项">
                            <el-card class="box-card">
                                <!--<div class="editor-content-see" v-html="info.attention"></div>-->
                                <span class="form-span-text">{{ info.attention }}</span>
                            </el-card>
                        </el-form-item>
                        <el-form-item>
                            <el-button size="medium" @click="isreturn">返回</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>

        </div>
    </div>
</template>
<script>
import Qnupload from '@/components/Qnupload'
import Amap from '@/components/Amap'
export default {
    components: {
        Qnupload,
        Amap
    },
    data() {
        return {
            loading: true,
            store_uuid: '',
            info: {},
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            let store_uuid = this.$route.query.store_uuid
            if (store_uuid !== undefined) {
                this.store_uuid = store_uuid
                this.getinfo()
            } else {
                this.$message({
                    message: '无查找对象',
                    duration: this.env.message_error,
                    onClose: () => {
                        this.$router.push({ path: '/store/store' })    // 返回列表
                    }
                });
            }
        },
        // 门店详情
        getinfo() {
            let thi = this
            let postdata = {
                api_name: "store.store.getinfo",
                token: this.Tool.get_l_cache('token'),
                store_uuid: this.store_uuid
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.info = json.data
                    setTimeout(function () {
                        thi.getposition()
                    }, 1000);
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 获取坐标
        getposition() {
            let thi = this
            let address = this.info.city + this.info.address
            this.$refs.amap_info.address2position(address, (json) => {
                if (json) {
                    this.$refs.amap_info.marker([json.lng, json.lat, thi.info.store_name])
                } else {
                    this.$message({
                        message: '根据地址查询位置失败', type: 'error',
                        duration: this.env.message_error,
                    });
                }
            });
        },
        // 操作提示
        operation_tip(store_uuid = '', store_name = '', operation = '', publish_state = null) {

            let tip = ''
            let options = {}
            // 上架
            if (operation === 'upapply') {
                tip = '上架【' + store_name + '】？'
            }
            // 下架
            if (operation === 'withdraw') {
                tip = '下架【' + store_name + '】？'
            }
            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                .then(() => {
                    this.isoperation(store_uuid, operation, publish_state)
                })
                .catch(() => {
                });
        },
        // 操作
        isoperation(store_uuid = '', operation = '', publish_state) {
            let postdata = {
                api_name: "store.store." + operation,
                token: this.Tool.get_l_cache('token'),
                store_uuid: store_uuid,
                publish_state,
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = true
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getinfo()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 返回
        isreturn() {
            this.$router.push({ path: '/store/store' })
        },
    }
}
</script>
<!--import Wangeditor from '@/components/Wangeditor'-->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
